import { useContext, useState } from "react";
import { AppContext } from "../providers/AppProvider";
import SectionTitle from "../components/SectionTitle";
import CreateOrganizationDialog from "../components/CreateOrganizationDialog";
import MyOrganizationsGrid from "../components/MyOrganizationsGrid";
import {
  OrganizationsProvider,
  OrganizationsContext,
} from "../providers/OrganizationsProvider";
import CircularProgress from "@mui/material/CircularProgress";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import styled from "@emotion/styled";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const MyOrganizations = () => {
  const { user } = useContext(AppContext);
  const { loadingOrganizations, loadOrganizations } =
    useContext(OrganizationsContext);
  const [dialogOpened, setDialogOpened] = useState(false);

  const openDialog = () => {
    setDialogOpened(true);
  };

  const closeDialog = (created) => {
    setDialogOpened(false);

    if (created) {
      loadOrganizations();
    }
  };

  return (
    <>
      {user && (
        <>
          <Wrapper>
            <SectionTitle text="My Organizations" />
            <Fab
              size="small"
              color="secondary"
              aria-label="add"
              onClick={openDialog}
            >
              <AddIcon />
            </Fab>
          </Wrapper>
          <CreateOrganizationDialog open={dialogOpened} onClose={closeDialog} />
          {loadingOrganizations ? (
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          ) : (
            <MyOrganizationsGrid />
          )}
        </>
      )}
    </>
  );
};

export default function Organizations() {
  return (
    <OrganizationsProvider>
      <MyOrganizations />
      {/* TODO: load public organizations (paginated) */}
    </OrganizationsProvider>
  );
}
