import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import { OrganizationsContext } from "../providers/OrganizationsProvider";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import CardActionArea from "@mui/material/CardActionArea";
import Button from "@mui/material/Button";
import { gql } from "../api";

const Image = styled.span`
  display: inline-block;
  width: 100%;
  height: 140px;
  background-color: #eee;
  background-size: cover;
`;

function CardImage({ src }) {
  return (
    <Image
      style={{
        backgroundImage: "url(" + src + ")",
      }}
      data-grid-lazy="true"
    />
  );
}

const Overlay = styled.div`
  background-color: rgba(238, 238, 238, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const InvitationButton = styled(Button)`
  margin: 10px;
`;

function InvitationOverlay({ organization }) {
  const [loading, setLoading] = useState(false);
  const { jwt } = useContext(AppContext);
  const { loadOrganizations } = useContext(OrganizationsContext);

  const updateInvitation = async (status) => {
    setLoading(true);

    try {
      const query = `
        mutation {
          invitations (update: $data, where: {organization_id: $organizationId}) {
            status
          }
        }
      `;

      const variables = {
        data: {
          status,
        },
        organizationId: organization.id,
      };

      const result = await gql({ jwt, query, variables });
      if (result.error) {
        throw result.error;
      }

      loadOrganizations();
    } catch (err) {
      console.log("error", err);
      // TODO: show error
    }

    setLoading(false);
  };

  const accept = () => {
    updateInvitation("accepted");
  };

  const decline = () => {
    updateInvitation("declined");
  };

  return (
    <Overlay>
      <InvitationButton
        disabled={loading}
        variant="contained"
        color="success"
        onClick={accept}
      >
        Accept
      </InvitationButton>
      <InvitationButton
        disabled={loading}
        variant="contained"
        color="error"
        onClick={decline}
      >
        Decline
      </InvitationButton>
    </Overlay>
  );
}

export default function OrganizationCard({ organization, isInvitation }) {
  const navigate = useNavigate();

  const viewOrganization = () => {
    navigate(`/organizations/${organization.id}`);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea onClick={viewOrganization}>
        <CardMedia component={() => <CardImage src={organization.image} />} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {organization.name}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {organization.description}
          </Typography>
        </CardContent>
      </CardActionArea>
      {isInvitation && <InvitationOverlay organization={organization} />}
    </Card>
  );
}
