import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import BackButton from "../components/BackButton";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export default function EventHeader({ event }) {
  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "grey.800",
        color: "#fff",
        mb: 4,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${event.image})`,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,.3)",
        }}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            sx={{
              position: "relative",
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Title>
              <BackButton />
              <Typography
                component="h1"
                variant="h3"
                color="inherit"
                sx={{ overflowWrap: "anywhere" }}
              >
                {event.title}
              </Typography>
            </Title>
            <Typography
              variant="h5"
              color="inherit"
              sx={{ overflowWrap: "anywhere" }}
              paragraph
            >
              {event.instanceDate.toLocaleString(navigator.language, {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
