import styled from "@emotion/styled";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingWrapper = styled.div`
display: flex;
width: 100%;
justify-content: center;
`;

export default function Loading({size=40}) {
    return (  
    <LoadingWrapper>
        <CircularProgress size={size} />
    </LoadingWrapper>
    );
}