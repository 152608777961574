import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import styled from "@emotion/styled";
import { gql } from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import OrganizationHeader from "../components/OrganizationHeader";
import SectionTitle from "../components/SectionTitle";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import WeeklyEvents from "../components/WeeklyEvents";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export default function ViewOrganizationEvents() {
  const { jwt, user } = useContext(AppContext);
  const { id } = useParams();
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(new Date());
  const navigate = useNavigate();

  useEffect(() => {
    const loadOrganization = async () => {
      try {
        const query = `
          query {
            organizations (where: {id: $id}) {
              id
              name
              description
              image
              location
              social_links
              organization_users (where: {user_id: $uid}) { role }
            }
          }
        `;

        const variables = {
          id,
          uid: user.id,
        };

        const result = await gql({ jwt, query, variables, organizationId: id });
        if (result.error) {
          throw result.error;
        }

        if (result.content.organizations.length === 0) {
          throw new Error("Organization not found");
        }

        const org = result.content.organizations[0];
        setOrganization(org);
      } catch (err) {
        console.log("error", err);
        // TODO: show error
      }

      setLoading(false);
    };

    loadOrganization();
  }, [id, jwt, user]);

  const isOwner = organization?.organization_users?.[0]?.role === "owner";

  const addEvent = () => {
    navigate(`/organizations/${organization.id}/events/add`);
  };

  const today = () => {
    setDate(new Date());
  };

  const prevWeek = () => {
    let d = new Date(date.valueOf());
    d.setDate(d.getDate() - 7);
    setDate(d);
  };

  const nextWeek = () => {
    let d = new Date(date.valueOf());
    d.setDate(d.getDate() + 7);
    setDate(d);
  };

  return loading ? (
    <LoadingWrapper>
      <CircularProgress />
    </LoadingWrapper>
  ) : (
    <>
      <OrganizationHeader organization={organization} />
      <Wrapper>
        <TitleWrapper>
          <SectionTitle text="Events" />
          {isOwner && (
            <Fab
              size="small"
              color="secondary"
              aria-label="add"
              onClick={addEvent}
            >
              <AddIcon />
            </Fab>
          )}
        </TitleWrapper>
        <ButtonsWrapper>
          <Button
            variant="outlined"
            size="small"
            onClick={today}
            sx={{ marginRight: 1 }}
            color="secondary"
          >
            Today
          </Button>
          <IconButton onClick={prevWeek}>
            <ChevronLeftIcon />
          </IconButton>
          <IconButton onClick={nextWeek}>
            <ChevronRightIcon />
          </IconButton>
        </ButtonsWrapper>
      </Wrapper>
      <WeeklyEvents organizationId={id} date={date} isOwner={isOwner} />
    </>
  );
}
