import {
  useContext,
  createContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { AppContext } from "./AppProvider";
import { gql } from "../api";

export const OrganizationsContext = createContext();

export function OrganizationsProvider({ children }) {
  const { jwt } = useContext(AppContext);
  const [organizations, setOrganizations] = useState([]);
  const [loadingOrganizations, setLoadingOrganizations] = useState(true);

  const loadOrganizations = useCallback(async () => {
    setLoadingOrganizations(true);

    try {
      const query = `
        query {
          invitations (where: {status: "pending"}) {
            organization {
              id
              name
              description
              image
            }
          }
          organization_users {
            role
            organization {
              id
              name
              description
              image
            }
          }
        }
      `;

      const result = await gql({ jwt, query });
      if (result.error) {
        throw result.error;
      }

      const organzationItems = [];

      result.content.invitations.forEach((item) => {
        organzationItems.push(item);
      });

      result.content.organization_users.forEach((item) => {
        organzationItems.push(item);
      });

      setOrganizations(organzationItems);
    } catch (err) {
      console.log("error", err);
      // TODO: show error
    }

    setLoadingOrganizations(false);
  }, [jwt]);

  useEffect(() => {
    if (!jwt) {
      return;
    }

    loadOrganizations();
  }, [jwt, loadOrganizations]);

  return (
    <OrganizationsContext.Provider
      value={{ loadOrganizations, loadingOrganizations, organizations }}
    >
      {children}
    </OrganizationsContext.Provider>
  );
}
