import { useContext, useEffect, useState } from "react";
import { OrganizationsContext } from "../providers/OrganizationsProvider";
import OrganizationCard from "./OrganizationCard";
import CircularProgress from "@mui/material/CircularProgress";
import { MasonryInfiniteGrid } from "@egjs/react-infinitegrid";
import styled from "@emotion/styled";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const GridWrapper = styled.div`
  padding: 20px;
`;

const Grid = styled(MasonryInfiniteGrid)`
  width: 100%;
`;

const Item = styled.div`
  display: inline-block;
  width: 250px;
`;

export default function MyOrganizationsGrid() {
  const { organizations } = useContext(OrganizationsContext);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const organzationItems = [];

    organizations.forEach((item, index) => {
      organzationItems.push({
        groupKey: 0,
        key: index,
        data: item,
      });
    });

    setItems(organzationItems);
  }, [organizations]);

  const onRequestAppend = (e) => {
    // TODO: add pagination?
  };

  return (
    <GridWrapper>
      <Grid
        gap={35}
        align={"start"}
        attributePrefix={"data-grid-"}
        loading={
          <LoadingWrapper>
            <CircularProgress />
          </LoadingWrapper>
        }
        onRequestAppend={onRequestAppend}
      >
        {items.map((item) => (
          <Item data-grid-groupkey={item.groupKey} key={item.key}>
            <OrganizationCard
              organization={item.data.organization}
              isInvitation={!item.data.role}
            />
          </Item>
        ))}
      </Grid>
    </GridWrapper>
  );
}
