import { useContext, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import styled from "@emotion/styled";
import { gql } from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import OrganizationHeader from "../components/OrganizationHeader";
import PostCard from "../components/PostCard";
import { MasonryInfiniteGrid } from "@egjs/react-infinitegrid";
import SectionTitle from "../components/SectionTitle";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Grid = styled(MasonryInfiniteGrid)`
  width: 100%;
`;

const Item = styled.div`
  display: inline-block;
  width: 48%;

  @media (max-width: 930px) {
    width: 100%;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const GridWrapper = styled.div`
  padding-bottom: 35px;
`;

export default function ViewOrganizationPosts() {
  const { jwt, user } = useContext(AppContext);
  const { id } = useParams();
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [unpublishedPosts, setUnpublishedPosts] = useState([]);
  const loadingPosts = useRef(false);
  const postsGroup = useRef(0);
  const endLoadingPosts = useRef(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadOrganization = async () => {
      try {
        const query = `
          query {
            organizations (where: {id: $id}) {
              id
              name
              description
              image
              location
              social_links
              organization_users (where: {user_id: $uid}) { role }
            }
            posts (where: { organization_id: $id, published_at: { is_null: true } }, order_by: { created_at: desc }) {
              id
              organization_id
              title
              body
              image
              created_at
            }
          }
        `;

        const variables = {
          id,
          uid: user.id,
        };

        const result = await gql({ jwt, query, variables, organizationId: id });
        if (result.error) {
          throw result.error;
        }

        if (result.content.organizations.length === 0) {
          throw new Error("Organization not found");
        }

        const org = result.content.organizations[0];
        setOrganization(org);

        if (result.content.posts) {
          setUnpublishedPosts(result.content.posts);
        }
      } catch (err) {
        console.log("error", err);
        // TODO: show error
      }

      setLoading(false);
    };

    loadOrganization();
  }, [id, jwt, user]);

  const onRequestAppend = (e) => {
    if (loadingPosts.current || endLoadingPosts.current) {
      return;
    }

    loadingPosts.current = true;

    const loadMorePosts = async () => {
      try {
        const query = `
          query {
            posts (where: { organization_id: $id }, order_by: { published_at: desc }, limit: 10, offset: $offset) {
              id
              organization_id
              title
              body
              image
              published_at
            }
          }
        `;

        const variables = {
          id,
          offset: posts.length > 0 ? posts.length + 1 : 0,
        };

        const result = await gql({ jwt, query, variables });
        if (result.error) {
          throw result.error;
        }

        if (result.content.posts.length > 0) {
          const items = [...posts];

          result.content.posts.forEach((post) => {
            items.push({
              groupKey: postsGroup.current,
              key: post.id,
              data: post,
            });
          });

          setPosts(items);
          postsGroup.current += 1;
        } else {
          endLoadingPosts.current = true;
        }
      } catch (err) {
        console.log("error", err);
        // TODO: show error
      }

      loadingPosts.current = false;
    };

    loadMorePosts();
  };

  const isOwner = organization?.organization_users?.[0]?.role === "owner";

  const addPost = () => {
    navigate(`/organizations/${organization.id}/posts/add`);
  };

  return (
    <>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <>
          <OrganizationHeader organization={organization} />
          {isOwner && (
            <Wrapper>
              <SectionTitle text="My Posts" />
              <Fab
                size="small"
                color="secondary"
                aria-label="add"
                onClick={addPost}
              >
                <AddIcon />
              </Fab>
            </Wrapper>
          )}
          <GridWrapper>
            <Grid
              gap={35}
              align={"start"}
              attributePrefix={"data-grid-"}
              useResizeObserver={true}
              observeChildren={true}
            >
              {unpublishedPosts.map((post) => (
                <Item data-grid-groupkey={0} key={post.id}>
                  <PostCard post={post} />
                </Item>
              ))}
            </Grid>
          </GridWrapper>
          <Grid
            gap={35}
            align={"start"}
            attributePrefix={"data-grid-"}
            loading={
              <LoadingWrapper>
                <CircularProgress />
              </LoadingWrapper>
            }
            onRequestAppend={onRequestAppend}
          >
            {posts.map((post) => (
              <Item data-grid-groupkey={post.groupKey} key={post.key}>
                <PostCard post={post.data} />
              </Item>
            ))}
          </Grid>
        </>
      )}
    </>
  );
}
