import { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

const NameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default function UserMenu() {
  const navigate = useNavigate();
  const { user, setJwt } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);

  const profile = () => {
    // TODO: link to profile page
  };

  const logout = () => {
    setJwt(null);
    navigate("/");
  };

  const menu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const close = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <NameWrapper>
        <Typography variant="subtitle1">{user.name}</Typography>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={menu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
      </NameWrapper>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={close}
      >
        <MenuItem onClick={profile}>MyProfile</MenuItem>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </div>
  );
}
