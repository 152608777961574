import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import BackButton from "../components/BackButton";
import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export default function OrganizationHeader({ organization }) {
  const navigate = useNavigate();

  const events = () => {
    navigate(`/organizations/${organization.id}/events`);
  };

  const posts = () => {
    navigate(`/organizations/${organization.id}/posts`);
  };

  const members = () => {
    navigate(`/organizations/${organization.id}/members`);
  };

  const isOwner = organization?.organization_users?.[0]?.role === "owner";

  return (
    <Paper
      sx={{
        position: "relative",
        backgroundColor: "grey.800",
        color: "#fff",
        mb: 4,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${organization.image})`,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          right: 0,
          left: 0,
          backgroundColor: "rgba(0,0,0,.3)",
        }}
      />
      <Grid container>
        <Grid item md={6}>
          <Box
            sx={{
              position: "relative",
              p: { xs: 3, md: 6 },
              pr: { md: 0 },
            }}
          >
            <Title>
              <BackButton />
              <Typography
                component="h1"
                variant="h3"
                color="inherit"
                sx={{ overflowWrap: "anywhere" }}
              >
                {organization.name}
              </Typography>
            </Title>
            <Typography
              variant="h5"
              color="inherit"
              sx={{ overflowWrap: "anywhere" }}
              paragraph
            >
              {organization.description}
            </Typography>
            {isOwner && (
              <>
                <Link
                  color="inherit"
                  noWrap
                  component="button"
                  variant="body2"
                  onClick={events}
                  sx={{ p: 1, flexShrink: 0 }}
                >
                  Events
                </Link>
                <Link
                  color="inherit"
                  noWrap
                  component="button"
                  variant="body2"
                  onClick={posts}
                  sx={{ p: 1, flexShrink: 0 }}
                >
                  Posts
                </Link>
                <Link
                  color="inherit"
                  noWrap
                  component="button"
                  variant="body2"
                  onClick={members}
                  sx={{ p: 1, flexShrink: 0 }}
                >
                  Members
                </Link>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}
