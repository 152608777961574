import { useState } from "react";
import styled from "@emotion/styled";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "../components/LoadingButton";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Weekdays from "../components/Weekdays";
import PlaceInput from "../components/PlaceInput";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { DateTime } from "luxon";
import Alert from "@mui/material/Alert";

const CustomDatePicker = styled(DatePicker)`
  flex-grow: 1;
`;

const CustomTimePicker = styled(TimePicker)`
  flex-grow: 1;
`;

const Error = styled(Alert)`
  margin-top: 20px;
`;

const EditorStyle = {
  borderRadius: "4px",
  padding: "1rem",
  border: "1px solid #ccc",
};

const ToolbarStyle = {
  borderRadius: "4px",
  border: "1px solid #ccc",
};

const formatDateTime = (dt) => {
  if (!dt) {
    return null;
  }

  return `${dt.toISODate()} ${dt.toISOTime()}`;
};

const getDuration = (event) => {
  if (event.duration % 60 === 0) {
    return {
      quantity: event.duration / 60,
      unit: "hours",
    };
  }
  return {
    quantity: event.duration,
    unit: "minutes",
  };
};

export default function EventForm({ event, onSubmit }) {
  const [title, setTitle] = useState(event?.title || "");
  const [image, setImage] = useState(event?.image || "");
  const [startDate, setStartDate] = useState(
    event ? DateTime.fromISO(event.start_date) : DateTime.now()
  );
  const [recurringEndDate, setRecurringEndDate] = useState(
    event?.recurring_end_date
      ? DateTime.fromISO(event.recurring_end_date)
      : null
  );
  const [recurring, setRecurring] = useState(event?.recurring || false);
  const [startTime, setStartTime] = useState(
    event
      ? DateTime.fromISO(event.start_date) // start_date contains the start_time
      : DateTime.now().set({ second: 0, millisecond: 0 })
  );
  const [duration, setDuration] = useState(
    event ? getDuration(event).quantity : 30
  );
  const [durationUnit, setDurationUnit] = useState(
    event ? getDuration(event).unit : "minutes"
  );
  const [place, setPlace] = useState(event?.place || null);
  const [isPublic, setIsPublic] = useState(event?.public || false);
  const [hasSeatsLimit, setHasSeatsLimit] = useState(event?.seats_quantity > 0);
  const [seatsQuantity, setSeatsQuantity] = useState(
    event?.seats_quantity || 10
  );
  const [bookingConfirmation, setBookingConfirmation] = useState(
    event?.booking_confirmation || false
  );
  const [recurringFrequency, setRecurringFrequency] = useState(
    event?.recurring_frequency || "d"
  );
  const [recurringQuantity, setRecurringQuantity] = useState(
    event?.recurring_quantity || 1
  );
  const [recurringWeekdays, setRecurringWeekdays] = useState(
    event?.recurring_weekdays || "0000000"
  );
  const [recurringBooking, setRecurringBooking] = useState(
    event?.recurring_booking || false
  );
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState(null);

  const [editorState, setEditorState] = useState(() => {
    if (event?.description) {
      const contentBlock = htmlToDraft(event.description);
      return EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlock.contentBlocks)
      );
    }
    return EditorState.createEmpty();
  });

  const submit = async (e) => {
    e.preventDefault();
    setError(null);
    setProcessing(true);

    let endDate = null;

    if (recurringEndDate) {
      endDate = recurringEndDate
        .set({ hour: startTime.hour, minute: startTime.minute })
        .plus({
          [durationUnit]: duration,
        });
    } else {
      // year = 3000 means no end date for us :)
      endDate = DateTime.now().set({ year: 3000 });
    }

    const durationMins = durationUnit === "minutes" ? duration : duration * 60;

    if (endDate <= startDate) {
      setError(
        "Last day of recurrency must be greater than the start date of the event"
      );
      setProcessing(false);
      return;
    }

    if (durationMins > 1440) {
      setError("Duration of the event can't be longer than 1 day");
      setProcessing(false);
      return;
    }

    const evt = {
      title,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      image,
      start_date: `${startDate.toISODate()} ${startTime.toISOTime()}`,
      start_time: startTime.toISOTime(),
      duration: durationMins,
      place,
      public: isPublic,
      seats_quantity: hasSeatsLimit ? seatsQuantity : null,
      booking_confirmation: bookingConfirmation,
      recurring,
      recurring_end_date: recurring
        ? formatDateTime(endDate)
        : formatDateTime(startDate),
      recurring_frequency: recurring ? recurringFrequency : null,
      recurring_quantity: recurring ? recurringQuantity : null,
      recurring_weekdays:
        recurring && recurringFrequency === "w" ? recurringWeekdays : null,
      recurring_booking: recurring ? recurringBooking : null,
    };

    await onSubmit(evt);
    setProcessing(false);
  };

  return (
    <Box component="form" onSubmit={submit} noValidate sx={{ mt: 3 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            name="title"
            id="title"
            label="Title"
            autoFocus
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            id="image"
            label="Image"
            name="image"
            value={image}
            onChange={(e) => {
              setImage(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Editor
            editorStyle={EditorStyle}
            toolbarStyle={ToolbarStyle}
            editorState={editorState}
            onEditorStateChange={setEditorState}
            placeholder="Description"
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid container item xs={12} sm={6}>
              <CustomDatePicker
                views={["day"]}
                inputFormat="DD"
                label="Start Date"
                value={startDate}
                onChange={setStartDate}
                renderInput={(params) => <TextField {...params} />}
                disableMaskedInput
              />
            </Grid>
            <Grid container item xs={12} sm={6} justifyContent="flex-end">
              <CustomTimePicker
                label="Start Time"
                value={startTime}
                onChange={setStartTime}
                renderInput={(params) => <TextField {...params} />}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid container item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Duration"
                type="number"
                value={duration}
                onChange={(e) => {
                  const val = e.target.value;
                  if (val >= 0) {
                    setDuration(val);
                  }
                }}
              />
            </Grid>
            <Grid container item xs={12} sm={6} justifyContent="flex-end">
              <FormControl fullWidth>
                <InputLabel id="duration-unit">Duration Unit</InputLabel>
                <Select
                  labelId="duration-unit"
                  id="duration-unit"
                  value={durationUnit}
                  label="Duration Unit"
                  onChange={(event) => setDurationUnit(event.target.value)}
                >
                  <MenuItem value="minutes">Minutes</MenuItem>
                  <MenuItem value="hours">Hours</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <PlaceInput place={place} onChange={setPlace} />
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid container item xs={12} sm={6}>
              <FormControlLabel
                label="Public Event"
                control={
                  <Checkbox
                    checked={isPublic}
                    onChange={(event) => setIsPublic(event.target.checked)}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid container item xs={12} sm={6}>
              <FormControlLabel
                label="Booking Confirmation"
                control={
                  <Checkbox
                    checked={bookingConfirmation}
                    onChange={(event) =>
                      setBookingConfirmation(event.target.checked)
                    }
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid container item xs={12} sm={6}>
              <FormControlLabel
                label="Seats Limit"
                control={
                  <Checkbox
                    checked={hasSeatsLimit}
                    onChange={(event) => setHasSeatsLimit(event.target.checked)}
                  />
                }
              />
            </Grid>
            {hasSeatsLimit && (
              <Grid container item xs={12} sm={6} justifyContent="flex-end">
                <TextField
                  required
                  fullWidth
                  label="Seats"
                  type="number"
                  value={seatsQuantity}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val >= 0) {
                      setSeatsQuantity(val);
                    }
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid container item xs={12} sm={6}>
              <FormControlLabel
                label="Recurring Event"
                control={
                  <Checkbox
                    checked={recurring}
                    onChange={(event) => setRecurring(event.target.checked)}
                  />
                }
              />
            </Grid>
            <Grid container item xs={12} sm={6} justifyContent="flex-end">
              {recurring && (
                <CustomDatePicker
                  views={["day"]}
                  inputFormat="DD"
                  label="Recurrency Last Date"
                  value={recurringEndDate}
                  onChange={setRecurringEndDate}
                  renderInput={(params) => <TextField {...params} />}
                  disableMaskedInput
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {recurring && (
          <>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid container item xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    label="Repeat every"
                    type="number"
                    value={recurringQuantity}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val >= 0) {
                        setRecurringQuantity(val);
                      }
                    }}
                  />
                </Grid>
                <Grid container item xs={12} sm={6} justifyContent="flex-end">
                  <FormControl fullWidth>
                    <Select
                      id="recurring-frequency"
                      value={recurringFrequency}
                      onChange={(event) =>
                        setRecurringFrequency(event.target.value)
                      }
                    >
                      <MenuItem value="d">Days</MenuItem>
                      <MenuItem value="w">Weeks</MenuItem>
                      <MenuItem value="m">Months</MenuItem>
                      <MenuItem value="y">Years</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container justifyContent="space-between" spacing={2}>
                <Grid container item xs={12} sm={6}>
                  <FormControlLabel
                    label="Recurring Booking"
                    control={
                      <Checkbox
                        checked={recurringBooking}
                        onChange={(event) =>
                          setRecurringBooking(event.target.checked)
                        }
                      />
                    }
                  />
                </Grid>
                {recurringFrequency === "w" && (
                  <Grid container item xs={12} sm={6}>
                    <Weekdays
                      value={recurringWeekdays}
                      onChange={setRecurringWeekdays}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {error && <Error severity="error">{error}</Error>}
      <LoadingButton text="Save" loading={processing} />
    </Box>
  );
}
