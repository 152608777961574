import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router-dom";

export default function ViewEventDialog({ isOwner, event, open, onClose }) {
  const navigate = useNavigate();

  const editEvent = () => {
    navigate(`/organizations/${event.organization_id}/events/${event.id}/edit`);
  };

  const viewEvent = () => {
    navigate(`/organizations/${event.organization_id}/events/${event.id}`, {
      state: { event },
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      {event && (
        <Card sx={{ maxWidth: 345, padding: 1 }}>
          <CardHeader
            action={
              <>
                {isOwner ? (
                  <IconButton aria-label="edit" onClick={editEvent}>
                    <EditIcon />
                  </IconButton>
                ) : (
                  <IconButton aria-label="view" onClick={viewEvent}>
                    <OpenInNewIcon />
                  </IconButton>
                )}
              </>
            }
            title={event.title}
            subheader={event.instanceDate.toLocaleString(navigator.language, {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          />
          <CardMedia
            component="img"
            height="194"
            image={event.image}
            alt="Event image"
          />
          <CardContent>
            <div dangerouslySetInnerHTML={{ __html: event.description }} />
          </CardContent>
        </Card>
      )}
    </Dialog>
  );
}
