// https://www.npmjs.com/package/leaflet-geosearch
// https://www.npmjs.com/package/react-leaflet
import { useState, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import { useDebounce } from "use-debounce";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import L from "leaflet";
import { useMap } from "react-leaflet/hooks";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const MapWrapper = styled(MapContainer)`
  width: 100%;
  height: 300px;
`;

const provider = new OpenStreetMapProvider();
const mapInitialPosition = [-34.90328, -56.18816]; // Montevideo

function Map({ position }) {
  const map = useMap();

  useEffect(() => {
    if (!map || !position) {
      return;
    }
    map.panTo(position);
  }, [map, position]);

  return null;
}

export default function PlaceInput({ place, onChange }) {
  const [open, setOpen] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState(place?.label || "");
  const [query] = useDebounce(searchCriteria, 500);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState(place ? [place.y, place.x] : null);
  const lastQuery = useRef("");

  useEffect(() => {
    if (loading || !query || query === lastQuery.current) {
      return;
    }

    lastQuery.current = query;

    const search = async () => {
      setLoading(true);
      const results = await provider.search({ query });
      const newResults = results.reduce((arr, item) => {
        if (!arr.find((arrItem) => arrItem.label === item.label)) {
          arr.push(item);
        }
        return arr;
      }, []);
      setOptions(newResults);
      setLoading(false);
    };

    search();
  }, [query, loading]);

  return (
    <Grid container spacing={1}>
      <Grid container item xs={12} sm={12} justifyContent="flex-end">
        <Autocomplete
          fullWidth
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          isOptionEqualToValue={(option, value) =>
            option.x === value.x && option.y === value.y
          }
          onChange={(event, newValue) => {
            let place = null;

            if (newValue) {
              setPosition([newValue.y, newValue.x]);

              place = {
                label: newValue.label,
                x: newValue.x,
                y: newValue.y,
                placeId: newValue.raw?.place_id,
              };
            }

            onChange && onChange(place);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              // Prevent's default 'Enter' behavior.
              event.defaultMuiPrevented = true;
              event.defaultPrevented = true;
              event.nativeEvent.preventDefault();
            }
          }}
          filterOptions={(x) => x}
          freeSolo
          getOptionLabel={(option) => option.label}
          options={options}
          loading={loading}
          value={place}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Place"
              value={searchCriteria}
              onChange={(e) => {
                setSearchCriteria(e.target.value);
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid container item xs={12} sm={12} justifyContent="flex-end">
        <MapWrapper center={mapInitialPosition} zoom={13}>
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Map position={position} />
          {position && <Marker position={position} />}
        </MapWrapper>
      </Grid>
    </Grid>
  );
}
