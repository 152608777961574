import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import styled from "@emotion/styled";
import { gql } from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import BackButton from "../components/BackButton";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LoadingButton from "../components/LoadingButton";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { useNavigate } from "react-router-dom";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const EditorStyle = {
  borderRadius: "4px",
  padding: "1rem",
  border: "1px solid #ccc",
};

const ToolbarStyle = {
  borderRadius: "4px",
  border: "1px solid #ccc",
};

export default function AddPost() {
  const { jwt } = useContext(AppContext);
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [adding, setAdding] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const navigate = useNavigate();

  useEffect(() => {
    const loadOrganizationData = async () => {
      setLoading(true);

      try {
        const query = `
          query {
            organizations (where: {id: $id}) {
              id
              name
            }
          }
        `;

        const variables = { id };

        const result = await gql({ jwt, query, variables });
        if (result.error) {
          throw result.error;
        }

        if (result.content.organizations.length === 0) {
          throw new Error("Organization not found");
        }

        const org = result.content.organizations[0];
        setOrganization(org);
      } catch (err) {
        console.log("error", err);
      }

      setLoading(false);
    };

    loadOrganizationData();
  }, [id, jwt]);

  const submit = async (e) => {
    e.preventDefault();

    setAdding(true);
    setError(false); // TODO: Handle errors

    // TODO: validate input data
    try {
      const query = `
        mutation {
          posts(insert: $data) {
            id
          }
        }
      `;

      const variables = {
        data: {
          title,
          body: draftToHtml(convertToRaw(editorState.getCurrentContent())),
          image,
        },
      };

      const result = await gql({ jwt, query, variables, organizationId: id });
      if (result.error) {
        throw result.error;
      }

      navigate(`/organizations/${id}/posts`);
    } catch (err) {
      console.log("error", err);
    }

    setAdding(false);
  };

  return loading ? (
    <LoadingWrapper>
      <CircularProgress />
    </LoadingWrapper>
  ) : (
    <>
      <Title>
        <BackButton />
        <Typography component="h1" variant="h5" color="inherit">
          Add Post for {organization.name}
        </Typography>
      </Title>
      <Container component="main" maxWidth="sm">
        <Box component="form" onSubmit={submit} noValidate sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                name="title"
                id="title"
                label="Title"
                autoFocus
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="image"
                label="Image"
                name="image"
                value={image}
                onChange={(e) => {
                  setImage(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Editor
                editorStyle={EditorStyle}
                toolbarStyle={ToolbarStyle}
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />
            </Grid>
          </Grid>
          <LoadingButton text="Save" loading={adding} />
          {error && (
            <Alert severity="error">
              Could not save post, try again later.
            </Alert>
          )}
        </Box>
      </Container>
    </>
  );
}
