import styled from "@emotion/styled";
import Delete from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

const Button = styled(Avatar)`
  cursor: pointer;
`;

export default function DeleteButton({ onClick }) {
  return (
    <Tooltip title="Delete">
      <Button sx={{ m: 1, bgcolor: "secondary.main" }} onClick={onClick}>
        <Delete />
      </Button>
    </Tooltip>
  );
}
