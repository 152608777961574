import { useState, useContext } from "react";
import { AppContext } from "../providers/AppProvider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import { gql } from "../api";

const LoadingWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: center;
`;

export default function InviteMemberDialog({ open, onClose, organization }) {
  const { jwt } = useContext(AppContext);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // TODO: add location and social links

  const confirm = () => {
    const createOrganizationInvite = async () => {
      setLoading(true);

      try {
        const query = `
          mutation {
            invitations (upsert: $data, where: { email: $email }) {
              status
            }
          }
        `;

        const variables = {
          data: {
            email,
          },
          email,
        };

        const result = await gql({
          jwt,
          query,
          variables,
          organizationId: organization.id,
        });
        if (result.error) {
          throw result.error;
        }

        onClose(true);
      } catch (err) {
        console.log("error", err);
        // TODO: show error
      }

      setLoading(false);
    };

    createOrganizationInvite();
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose(false);
      }}
    >
      <DialogTitle>Invite Organization Member</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="email"
          label="Email"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
      </DialogContent>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress
            size={20}
            sx={{
              marginTop: "3px",
              marginBottom: "3px",
            }}
          />
        </LoadingWrapper>
      ) : (
        <DialogActions>
          <Button
            onClick={() => {
              onClose(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={confirm}>Confirm</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
