import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import styled from "@emotion/styled";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import InviteMemberDialog from "./InviteMemberDialog";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const LinkWrapper = styled.div`
  display: flex;
  max-width: 650px;
  justify-content: flex-end;
  width: 100%;
`;

const Cell = styled(TableCell)`
  word-wrap: anywhere;
  padding: 10px 5px;
`;

export default function MembersGrid({ organization, invitations, reloadData }) {
  const [dialogOpened, setDialogOpened] = useState(false);

  const openDialog = () => {
    setDialogOpened(true);
  };

  const closeDialog = (reload) => {
    setDialogOpened(false);
    if (reload) {
      reloadData();
    }
  };

  return (
    <>
      <InviteMemberDialog
        open={dialogOpened}
        onClose={closeDialog}
        organization={organization}
      />
      <TableWrapper>
        <LinkWrapper>
          <Link
            color="inherit"
            noWrap
            component="button"
            variant="body2"
            onClick={openDialog}
            sx={{ p: 1, flexShrink: 0, width: "150px", textAlign: "right" }}
          >
            Invite member
          </Link>
        </LinkWrapper>
        <TableContainer sx={{ maxWidth: 650 }} component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <Cell>Name</Cell>
                <Cell>Email</Cell>
                <Cell>Role</Cell>
                <Cell>Status</Cell>
                <Cell align="right"></Cell>
              </TableRow>
            </TableHead>
            <TableBody>
              {organization.organization_users.map((row) => (
                <TableRow
                  key={row.users.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <Cell component="th" scope="row">
                    {row.users.name}
                  </Cell>
                  <Cell>{row.users.email}</Cell>
                  <Cell>{row.role}</Cell>
                  <Cell>Active</Cell>
                  <Cell align="right"></Cell>
                </TableRow>
              ))}
              {invitations.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <Cell component="th" scope="row">
                    -
                  </Cell>
                  <Cell>{row.email}</Cell>
                  <Cell>-</Cell>
                  <Cell>Pending</Cell>
                  <Cell align="right"></Cell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </TableWrapper>
    </>
  );
}
