import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import styled from "@emotion/styled";
import { gql } from "../api";
import BackButton from "../components/BackButton";
import EditButton from "../components/EditButton";
import DeleteButton from "../components/DeleteButton";
import PublishButton from "../components/PublishButton";
import UnpublishButton from "../components/UnpublishButton";
import ConfirmDialog from "../components/ConfirmDialog";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const PostWrapper = styled.div`
  padding: 10px 100px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
`;

const TitleWrapper = styled.div`
  flex: 2;
`;

const ImageWrapper = styled.div`
  flex: 1;
`;

const Image = styled.div`
  background-image: url(${({ src }) => src});
  height: 200px;
  background-size: cover;
`;

export default function ViewPost() {
  const { jwt } = useContext(AppContext);
  const { id, organizationId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const loadPost = async () => {
      try {
        const query = `
          query {
            posts (where: {id: $id}) {
              id
              title
              body
              image
              published_at
              created_at
              users {
                name
              }
            }
          }
        `;

        const variables = { id };

        const result = await gql({ jwt, query, variables, organizationId });
        if (result.error) {
          throw result.error;
        }

        if (result.content.posts.length === 0) {
          throw new Error("Post not found");
        }

        const p = result.content.posts[0];
        setPost(p);
      } catch (err) {
        console.log("error", err);
        // TODO: show error
      }

      setLoading(false);
    };

    loadPost();
  }, [id, jwt, organizationId]);

  const publishPost = async () => {
    setLoading(true);
    // TODO: Handle errors

    try {
      const query = `
        mutation {
          posts (update: $data, where: { id: $id }) {}
        }`;

      const variables = {
        id,
        data: {
          published_at: Math.floor(new Date().getTime() / 1000),
        },
      };

      const result = await gql({ jwt, query, variables, organizationId });
      if (result.error) {
        throw result.error;
      }

      navigate(`/organizations/${organizationId}/posts`);
    } catch (err) {
      console.log("error", err);
    }

    setLoading(false);
  };

  const unpublishPost = async () => {
    setLoading(true);
    // TODO: Handle errors

    try {
      const query = `
        mutation {
          posts (update: $data, where: { id: $id }) {}
        }`;

      const variables = {
        id,
        data: {
          published_at: null,
        },
      };

      const result = await gql({ jwt, query, variables, organizationId });
      if (result.error) {
        throw result.error;
      }

      navigate(`/organizations/${organizationId}/posts`);
    } catch (err) {
      console.log("error", err);
    }

    setLoading(false);
  };

  const editPost = () => {
    navigate(`/organizations/${organizationId}/posts/${id}/edit`);
  };

  const deletePost = () => {
    setOpenDialog(true);
  };

  const confirmDelete = async () => {
    setSaving(true);
    // TODO: Handle errors

    try {
      const query = `
        mutation {
          posts (delete: true, id: $id) {}
        }`;

      const variables = {
        id,
      };

      const result = await gql({ jwt, query, variables, organizationId });
      if (result.error) {
        throw result.error;
      }

      navigate(`/organizations/${organizationId}/posts`);
    } catch (err) {
      console.log("error", err);
      setSaving(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <ButtonsWrapper>
            <BackButton />
            <EditButton onClick={editPost} />
            {!post.published_at && <PublishButton onClick={publishPost} />}
            {post.published_at && <UnpublishButton onClick={unpublishPost} />}
            <DeleteButton onClick={deletePost} />
          </ButtonsWrapper>
          <PostWrapper>
            <Header>
              <TitleWrapper>
                <Title>
                  <Typography
                    variant="h3"
                    color="inherit"
                    sx={{ overflowWrap: "anywhere" }}
                  >
                    {post.title}
                  </Typography>
                </Title>
                <Typography
                  variant="subtitle1"
                  color="inherit"
                  sx={{ overflowWrap: "anywhere" }}
                  paragraph
                >
                  {(post.published_at ? "Published: " : "Created: ") +
                    new Date(
                      (post.published_at || post.created_at) * 1000
                    ).toLocaleString(navigator.language)}
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="inherit"
                  sx={{ overflowWrap: "anywhere" }}
                  paragraph
                >
                  {"By: " + post.users.name}
                </Typography>
              </TitleWrapper>
              <ImageWrapper>
                <Image src={post.image} />
              </ImageWrapper>
            </Header>
            <div dangerouslySetInnerHTML={{ __html: post.body }} />
          </PostWrapper>
          <ConfirmDialog
            open={openDialog}
            title="Delete Post"
            content="Are you sure you want to delete this post?"
            onCancel={() => {
              setOpenDialog(false);
            }}
            onConfirm={confirmDelete}
            loading={saving}
          />
        </>
      )}
    </>
  );
}
