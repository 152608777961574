import Grid from "@mui/material/Grid";
import OrganizationHeader from "./OrganizationHeader";
import PostCard from "./PostCard";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export default function OrganizationInformation({ organization }) {
  const navigate = useNavigate();

  const posts = () => {
    navigate(`/organizations/${organization.id}/posts`);
  };

  return (
    <>
      <OrganizationHeader organization={organization} />
      <Grid container spacing={4}>
        {organization.posts.map((post, i) => (
          <PostCard key={i} post={post} />
        ))}
      </Grid>
      <LinksWrapper>
        <Link
          color="inherit"
          noWrap
          component="button"
          variant="body2"
          onClick={posts}
          sx={{ p: 1, flexShrink: 0 }}
        >
          View all posts
        </Link>
      </LinksWrapper>
    </>
  );
}
