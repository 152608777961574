import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

export default function LoadingButton({ loading, text }) {
  return (
    <Button
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      disabled={loading}
    >
      {loading ? (
        <CircularProgress
          size={20}
          sx={{
            marginTop: "3px",
            marginBottom: "3px",
          }}
        />
      ) : (
        text
      )}
    </Button>
  );
}
