import {
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import styled from "@emotion/styled";
import { DayPilotCalendar } from "@daypilot/daypilot-lite-react";
import CircularProgress from "@mui/material/CircularProgress";
import { AppContext } from "../providers/AppProvider";
import { gql } from "../api";
import { DateTime } from "luxon";
import randomcolor from "randomcolor";
import ViewEventDialog from "./ViewEventDialog";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const LoadingOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.05);
`;

const LoadingWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  top: 100px;
  z-index: 100;
  display: flex;
  justify-content: center;
`;

export default function WeeklyEvents({ organizationId, date, isOwner }) {
  const { jwt, user } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const calendarRef = useRef();
  const [eventsData, setEventsData] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);

  useEffect(() => {
    setEventsData(null);
  }, [date]);

  const startOfWeek = useMemo(() => {
    const dateTime = DateTime.fromJSDate(date);
    return dateTime.minus({ days: dateTime.weekday }).startOf("day");
  }, [date]);

  const endOfWeek = useMemo(() => {
    return startOfWeek.plus({ days: 7 });
  }, [startOfWeek]);

  useEffect(() => {
    const loadEvents = async () => {
      setLoading(true);

      try {
        const query = `
          query {
            events (
              where: {
                and: {
                  start_date: {
                    lt: $endDate
                  },
                  recurring_end_date: {
                    gte: $startDate
                  }
                }
              }
            ) {
              id
              organization_id
              title
              description
              image
              start_date
              duration
              recurring
              recurring_end_date
              recurring_frequency
              recurring_quantity
              recurring_weekdays
            }
          }
        `;

        const variables = {
          startDate: `${startOfWeek.toISODate()} 00:00:00`,
          endDate: `${endOfWeek.toISODate()} 00:00:00`,
        };

        const result = await gql({ jwt, query, variables, organizationId });
        if (result.error) {
          throw result.error;
        }

        setEventsData({
          startOfWeek,
          events: result.content.events,
        });
      } catch (err) {
        console.log("error", err);
        // TODO: show error
      }

      setLoading(false);
    };

    loadEvents();
  }, [organizationId, jwt, user, startOfWeek, endOfWeek]);

  const addEvents = useCallback(() => {
    if (!calendarRef.current || !eventsData) {
      return;
    }

    const calendarEvents = eventsData.events.reduce((arr, evt) => {
      const addEvent = (lst) => {
        const start = `${lst.toISODate()}T${lst.toISOTime().substring(0, 8)}`;
        const et = lst.plus({ minutes: evt.duration });
        const end = `${et.toISODate()}T${et.toISOTime().substring(0, 8)}`;

        arr.push({
          id: evt.id,
          start,
          end,
          html: `<div style="font-size: 14px;">${evt.title}</div>`,
          backColor: randomcolor({
            seed: evt.id,
            luminosity: "light",
          }),
          borderColor: "transparent",
          event: evt,
        });
      };

      const datesDiff = (startDate, endDate, unit) => {
        if (unit === "d") {
          return endDate.diff(startDate, ["days", "hours"]).toObject().days;
        } else if (unit === "w") {
          return endDate.diff(startDate, ["weeks", "days"]).toObject().weeks;
        } else if (unit === "m") {
          return endDate.diff(startDate, ["months", "days"]).toObject().months;
        } else {
          // unit === "y"
          return endDate.diff(startDate, ["years", "months"]).toObject().years;
        }
      };

      let startDate = DateTime.fromJSDate(new Date(evt.start_date));
      const evtStartTime = {
        hour: startDate.hour,
        minute: startDate.minute,
      };
      startDate = startDate.set({ hour: 0, minute: 0 });

      if (evt.recurring) {
        const recurringEndDate = DateTime.fromJSDate(
          new Date(evt.recurring_end_date)
        ).set({ hour: 0, minute: 0 });

        for (let i = 0; i < 7; i++) {
          const iterDate = eventsData.startOfWeek.plus({ days: i });
          const dt = iterDate.set(evtStartTime);

          if (
            iterDate >= startDate &&
            iterDate <= recurringEndDate &&
            // matches recurrency
            datesDiff(startDate, iterDate, evt.recurring_frequency) %
              evt.recurring_quantity ===
              0
          ) {
            if (evt.recurring_frequency === "d") {
              addEvent(dt);
            } else if (evt.recurring_frequency === "w") {
              const weekday = iterDate.weekday % 7; // luxon weekday: 1 is Monday and 7 is Sunday
              const weekdaysArr = evt.recurring_weekdays.split("");
              // day in weekdays
              weekdaysArr[weekday] === "1" && addEvent(dt);
            } else if (evt.recurring_frequency === "m") {
              // same day number
              iterDate.day === startDate.day && addEvent(dt);
            } else {
              // evt.recurring_frequency === "y"
              // same month number && same day number
              iterDate.month === startDate.month &&
                iterDate.day === startDate.day &&
                addEvent(dt);
            }
          }
        }
      } else {
        const dt = startDate.set(evtStartTime);
        addEvent(dt);
      }

      return arr;
    }, []);

    calendarRef.current.control.update({ events: calendarEvents });
  }, [eventsData]);

  useEffect(() => {
    addEvents();
  }, [addEvents]);

  return (
    <>
      <Wrapper>
        {loading && (
          <>
            <LoadingOverlay />
            <LoadingWrapper>
              <CircularProgress />
            </LoadingWrapper>
          </>
        )}
        <DayPilotCalendar
          ref={calendarRef}
          viewType="Week"
          startDate={date}
          durationBarVisible={false}
          timeRangeSelectedHandling="Disabled"
          eventMoveHandling="Disabled"
          eventDeleteHandling="Disabled"
          eventResizeHandling="Disabled"
          onEventClick={(args) => {
            const event = args.e.data.event;
            event.instanceDate = new Date(args.e.data.start.value);
            setSelectedEvent(event);
          }}
        />
      </Wrapper>
      <ViewEventDialog
        isOwner={isOwner}
        event={selectedEvent}
        open={selectedEvent !== null}
        onClose={() => {
          setSelectedEvent(null);
        }}
      />
    </>
  );
}
