import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import styled from "@emotion/styled";
import { gql } from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import OrganizationInformation from "../components/OrganizationInformation";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export default function ViewOrganization() {
  const { jwt } = useContext(AppContext);
  const { id } = useParams();
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadOrganization = async () => {
      try {
        const query = `
          query {
            organizations (where: {id: $id}) {
              id
              name
              description
              image
              location
              social_links
              organization_users { role }
              posts (order_by: { published_at: desc }, limit: 2) {
                id
                organization_id
                title
                body
                image
                published_at
              }
            }
          }
        `;

        const variables = { id };

        const result = await gql({ jwt, query, variables });
        if (result.error) {
          throw result.error;
        }

        if (result.content.organizations.length === 0) {
          throw new Error("Organization not found");
        }

        const org = result.content.organizations[0];
        setOrganization(org);
      } catch (err) {
        console.log("error", err);
        // TODO: show error
      }

      setLoading(false);
    };

    loadOrganization();
  }, [id, jwt]);

  return (
    <>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      ) : (
        <OrganizationInformation organization={organization} />
      )}
    </>
  );
}
