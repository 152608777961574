import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { gql } from "../api";

export const AppContext = createContext();

export function AppProvider({ children }) {
  const [user, setUser] = useState(null);
  const [jwt, setJwt] = useState(window.localStorage.getItem("jwt"));
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setUser(null);
    window.localStorage.setItem("jwt", jwt);

    if (!jwt) {
      setLoading(false);
      return;
    }

    const checkJwt = async () => {
      try {
        const decodedJwt = jwt_decode(jwt);

        const query = `
          query {
            users (id: $id) {
              name
            }
          }
        `;

        const variables = {
          id: decodedJwt.sub,
        };

        const result = await gql({ jwt, query, variables });
        if (result.error) {
          throw result.error;
        }

        setUser({
          id: decodedJwt.sub,
          name: result.content.users.name,
        });
      } catch (err) {
        console.log("error", err);
      }

      setLoading(false);
    };

    checkJwt();
  }, [setUser, jwt]);

  return (
    <AppContext.Provider value={{ user, setUser, jwt, setJwt, loading }}>
      {children}
    </AppContext.Provider>
  );
}
