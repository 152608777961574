import { useState, useContext } from "react";
import { AppContext } from "../providers/AppProvider";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import { gql } from "../api";

const LoadingWrapper = styled.div`
  display: flex;
  height: 40px;
  justify-content: center;
`;

export default function CreateOrganizationDialog({ open, onClose }) {
  const { jwt } = useContext(AppContext);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(false);

  // TODO: add location and social links

  const confirm = () => {
    const createOrganization = async () => {
      setLoading(true);

      try {
        const query = `
          mutation {
            organization_users(insert: $data) {
              organization {
                id
              }
            }
          }
        `;

        const variables = {
          data: {
            organization: {
              name,
              description,
              image,
            },
          },
        };

        const result = await gql({ jwt, query, variables });
        if (result.error) {
          throw result.error;
        }

        onClose(true);
      } catch (err) {
        console.log("error", err);
        // TODO: show error
      }

      setLoading(false);
    };

    createOrganization();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create Organization</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Name"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <TextField
          margin="dense"
          id="description"
          label="Description"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
        <TextField
          margin="dense"
          id="image"
          label="Image"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => {
            setImage(e.target.value);
          }}
        />
      </DialogContent>
      {loading ? (
        <LoadingWrapper>
          <CircularProgress
            size={20}
            sx={{
              marginTop: "3px",
              marginBottom: "3px",
            }}
          />
        </LoadingWrapper>
      ) : (
        <DialogActions>
          <Button
            onClick={() => {
              onClose(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={confirm}>Confirm</Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
