import styled from "@emotion/styled";
import Edit from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

const Button = styled(Avatar)`
  cursor: pointer;
`;

export default function EditButton({onClick}) {
  return (
    <Tooltip title="Edit">
      <Button
        sx={{ m: 1, bgcolor: "secondary.main" }}
        onClick={onClick}
      >
        <Edit/>
      </Button>
    </Tooltip>
  );
}
