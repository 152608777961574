import Typography from "@mui/material/Typography";
import styled from "@emotion/styled";

const Title = styled.div`
  padding: 20px;
`;

export default function SectionTitle({ text }) {
  return (
    <Title>
      <Typography variant="h5" gutterBottom component="div" mb={0}>
        {text}
      </Typography>
    </Title>
  );
}
