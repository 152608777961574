import styled from "@emotion/styled";
import Upload from "@mui/icons-material/Upload";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";


const Button = styled(Avatar)`
  cursor: pointer;
`;

export default function PublishButton({onClick}) {
  
  return (
    <Tooltip title="Publish">
      <Button
        sx={{ m: 1, bgcolor: "secondary.main" }}
        onClick={onClick}
      >
        <Upload />
      </Button>
    </Tooltip>
  );
}
