import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Loading from './Loading';
import styled from "@emotion/styled";


const ActionsWrapper = styled.div`
width: 100%;
height: 40px;
`;

export default function ConfirmDialog( {open, title, content, onCancel, onConfirm, loading}) {

  return (
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {content}
          </DialogContentText>
        </DialogContent>
        <ActionsWrapper>
          {loading ? <Loading size={20}/> : 
            <DialogActions sx={{
              paddingTop: "0",
              paddingBottom: "0",
            }}>
              <Button onClick={onCancel}>Cancel</Button>
              <Button onClick={onConfirm} autoFocus>
                Confirm
              </Button>
            </DialogActions>
          }
        </ActionsWrapper>
      </Dialog>
    )
}
