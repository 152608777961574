import { useContext, useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import styled from "@emotion/styled";
import { gql } from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import MembersGrid from "../components/MembersGrid";
import BackButton from "../components/BackButton";
import Typography from "@mui/material/Typography";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export default function OrganizationMembers() {
  const { jwt } = useContext(AppContext);
  const { id } = useParams();
  const [organization, setOrganization] = useState(null);
  const [invitations, setInvitations] = useState(null);
  const [loading, setLoading] = useState(true);

  const loadOrganizationData = useCallback(async () => {
    setLoading(true);

    try {
      const query = `
        query {
          invitations (where: {organization_id: $id, status: "pending"}) {
            email
            status
          }
          organizations (where: {id: $id}) {
            id
            name
            organization_users {
              role
              users {
                id
                name
                email
              }
            }
          }
        }
      `;

      const variables = { id };

      const result = await gql({ jwt, query, variables, organizationId: id });
      if (result.error) {
        throw result.error;
      }

      if (result.content.organizations.length === 0) {
        throw new Error("Organization not found");
      }

      const org = result.content.organizations[0];
      const inv = result.content.invitations;
      setOrganization(org);
      setInvitations(inv);
    } catch (err) {
      console.log("error", err);
      // TODO: show error
    }

    setLoading(false);
  }, [id, jwt]);

  useEffect(() => {
    loadOrganizationData();
  }, [loadOrganizationData]);

  return loading ? (
    <LoadingWrapper>
      <CircularProgress />
    </LoadingWrapper>
  ) : (
    <>
      <Title>
        <BackButton />
        <Typography component="h1" variant="h4" color="inherit">
          Members of {organization.name}
        </Typography>
      </Title>
      <MembersGrid
        organization={organization}
        invitations={invitations}
        reloadData={loadOrganizationData}
      />
    </>
  );
}
