import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import styled from "@emotion/styled";
import { gql } from "../api";
import CircularProgress from "@mui/material/CircularProgress";
import BackButton from "../components/BackButton";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import EventForm from "../components/EventForm";

const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

export default function AddEvent() {
  const { jwt } = useContext(AppContext);
  const { id } = useParams();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadOrganizationData = async () => {
      setLoading(true);

      try {
        const query = `
          query {
            organizations (where: {id: $id}) {
              id
              name
            }
          }
        `;

        const variables = { id };

        const result = await gql({ jwt, query, variables });
        if (result.error) {
          throw result.error;
        }

        if (result.content.organizations.length === 0) {
          throw new Error("Organization not found");
        }

        const org = result.content.organizations[0];
        setOrganization(org);
      } catch (err) {
        console.log("error", err);
      }

      setLoading(false);
    };

    loadOrganizationData();
  }, [id, jwt]);

  const submit = async (data) => {
    setError(false); // TODO: Handle errors

    // TODO: validate input data
    try {
      const query = `
        mutation {
          events(insert: $data) {
            id
          }
        }
      `;

      const variables = { data };

      const result = await gql({ jwt, query, variables, organizationId: id });
      if (result.error) {
        throw result.error;
      }

      navigate(`/organizations/${id}/events`);
    } catch (err) {
      console.log("error", err);
    }
  };

  return loading ? (
    <LoadingWrapper>
      <CircularProgress />
    </LoadingWrapper>
  ) : (
    <>
      <Title>
        <BackButton />
        <Typography component="h1" variant="h5" color="inherit">
          Add Event for {organization.name}
        </Typography>
      </Title>
      <Container component="main" maxWidth="sm">
        <EventForm onSubmit={submit} />
        {error && (
          <Alert severity="error">Could not save event, try again later.</Alert>
        )}
      </Container>
    </>
  );
}
