const API_URL = process.env.REACT_APP_API_URL;

export async function signup({ name, email, password }) {
  const result = {
    jwt: "",
    error: null,
  };

  try {
    const response = await fetch(API_URL + "/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: name.trim(),
        email: email.trim(),
        password: password.trim(),
      }),
    });

    const resp = await response.json();

    if (response.ok) {
      result.jwt = resp.jwt;
    } else {
      result.error = resp.error;
    }
  } catch (err) {
    result.error = err;
  }

  return result;
}

export async function login({ email, password }) {
  const result = {
    jwt: "",
    error: null,
  };

  try {
    const response = await fetch(API_URL + "/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email.trim(),
        password: password.trim(),
      }),
    });

    const resp = await response.json();

    if (response.ok) {
      result.jwt = resp.jwt;
    } else {
      result.error = resp.error;
    }
  } catch (err) {
    result.error = err;
  }

  return result;
}

export async function gql({ jwt, query, variables, organizationId }) {
  const result = {
    content: "",
    error: null,
  };

  try {
    let body = query;

    if (variables) {
      body += ` variables ${JSON.stringify(variables)}`;
    }

    const headers = {
      Authorization: jwt,
    };

    if (organizationId) {
      headers.Organization = organizationId;
    }

    const response = await fetch(API_URL + "/gql", {
      method: "POST",
      headers,
      body,
    });

    const resp = await response.json();

    if (response.ok) {
      result.content = resp;
    } else {
      result.error = resp.error;
    }
  } catch (err) {
    result.error = err;
  }

  return result;
}
