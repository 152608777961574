import styled from "@emotion/styled";
import ArrowBack from "@mui/icons-material/ArrowBack";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

import { useNavigate } from "react-router-dom";

const Button = styled(Avatar)`
  cursor: pointer;
`;

export default function BackButton() {
  const navigate = useNavigate();
  return (
    <Tooltip title="Back">
      <Button
        sx={{ m: 1, bgcolor: "secondary.main" }}
        onClick={() => {
          navigate(-1);
        }}
      >
        <ArrowBack />
      </Button>
    </Tooltip>
  );
}
