import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import ClampLines from "react-clamp-lines";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";

const Overlay = styled.div`
  background-color: rgba(238, 238, 238, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
`;


const DraftLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: gray;
  color: white;
  padding: 10px;
`;


export default function PostCard({ post }) {
  const navigate = useNavigate();

  const viewPost = () => {
    navigate(`/organizations/${post.organization_id}/posts/${post.id}`);
  };

  return (
    <Grid item xs={12} md={6}>
      <CardActionArea onClick={viewPost}>
        <Card sx={{ display: "flex" }}>        
          <CardContent sx={{ flex: 1 }}>
            <Typography component="h2" variant="h5">
              {post.title}
            </Typography>
            <Typography variant="subtitle1" color="text.secondary">
              {new Date( (post.published_at || post.created_at) * 1000).toLocaleString(
                navigator.language
              )}
            </Typography>
            <Typography component="div" variant="subtitle1" paragraph>
              <ClampLines
                text={post.body
                  .replaceAll(/<br\/>/g, "\n")
                  .replaceAll(/<[^>]+>/g, "")}
                id={post.id}
                lines={1}
                ellipsis="..."
                buttons={false}
              />
            </Typography>
          </CardContent>
          <CardMedia
            component="img"
            sx={{ width: 160, display: { xs: "none", sm: "block" } }}
            image={post.image}
          />        
        </Card>
        {!post.published_at && (
          <Overlay>
            <DraftLabel>Draft</DraftLabel>
          </Overlay>
        )}
      </CardActionArea>      
    </Grid>
  );
}
