import { useContext } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import SignUp from "./pages/SignUp";
import LogIn from "./pages/LogIn";
import Events from "./pages/Events";
import Organizations from "./pages/Organizations";
import ViewOrganization from "./pages/ViewOrganization";
import ViewOrganizationPosts from "./pages/ViewOrganizationPosts";
import ViewOrganizationEvents from "./pages/ViewOrganizationEvents";
import NotFound from "./pages/NotFound";
import { AppContext } from "./providers/AppProvider";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import Container from "@mui/material/Container";
import Header from "./components/Header";
import OrganizationMembers from "./pages/OrganizationMembers";
import AddPost from "./pages/AddPost";
import AddEvent from "./pages/AddEvent";
import EditPost from "./pages/EditPost";
import EditEvent from "./pages/EditEvent";
import ViewPost from "./pages/ViewPost";
import ViewEvent from "./pages/ViewEvent";

const Wrapper = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default function App() {
  const { loading } = useContext(AppContext);

  return loading ? (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  ) : (
    <Container maxWidth="lg">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="login" element={<LogIn />} />
        <Route path="events" element={<Events />} />
        <Route path="organizations" element={<Organizations />} />
        <Route path="organizations/:id" element={<ViewOrganization />} />
        <Route
          path="organizations/:id/events"
          element={<ViewOrganizationEvents />}
        />
        <Route path="organizations/:id/events/add" element={<AddEvent />} />
        <Route
          path="organizations/:organizationId/events/:id"
          element={<ViewEvent />}
        />
        <Route
          path="organizations/:organizationId/events/:id/edit"
          element={<EditEvent />}
        />
        <Route
          path="organizations/:id/posts"
          element={<ViewOrganizationPosts />}
        />
        <Route path="organizations/:id/posts/add" element={<AddPost />} />
        <Route
          path="organizations/:organizationId/posts/:id"
          element={<ViewPost />}
        />
        <Route
          path="organizations/:organizationId/posts/:id/edit"
          element={<EditPost />}
        />
        <Route
          path="organizations/:id/members"
          element={<OrganizationMembers />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Container>
  );
}
