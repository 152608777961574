import { useState, useEffect } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const strToArr = (str) => {
  const result = [];
  if (!str) {
    return result;
  }

  const chars = str.split("");

  for (let i = 0; i < chars.length; i++) {
    if (i < 7 && chars[i] === "1") {
      result.push(i);
    }
  }

  return result;
};

const arrToStr = (arr) => {
  let result = ["0", "0", "0", "0", "0", "0", "0"];

  for (let i = 0; i < arr.length; i++) {
    result[arr[i]] = "1";
  }

  return result.join("");
};

export default function Weekdays({ value, onChange }) {
  const [selectedDays, setSelectedDays] = useState(strToArr(value));

  const handleChange = (_, selection) => {
    setSelectedDays(selection);
  };

  useEffect(() => {
    onChange && onChange(arrToStr(selectedDays));
  }, [selectedDays, onChange]);

  return (
    <ToggleButtonGroup
      value={selectedDays}
      onChange={handleChange}
      sx={{ flexGrow: 1 }}
    >
      <ToggleButton value={0} sx={{ flex: 1 }}>
        S
      </ToggleButton>
      <ToggleButton value={1} sx={{ flex: 1 }}>
        M
      </ToggleButton>
      <ToggleButton value={2} sx={{ flex: 1 }}>
        T
      </ToggleButton>
      <ToggleButton value={3} sx={{ flex: 1 }}>
        W
      </ToggleButton>
      <ToggleButton value={4} sx={{ flex: 1 }}>
        T
      </ToggleButton>
      <ToggleButton value={5} sx={{ flex: 1 }}>
        F
      </ToggleButton>
      <ToggleButton value={6} sx={{ flex: 1 }}>
        S
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
