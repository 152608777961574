import Container from "@mui/material/Container";
import { useLocation } from "react-router-dom";
import NotFound from "./NotFound";
import EventHeader from "../components/EventHeader";

export default function ViewEvent() {
  const { state } = useLocation();
  const event = state?.event;

  if (!event) {
    return <NotFound />;
  }

  return (
    <>
      <EventHeader event={event} />
      <Container component="main">
        <div dangerouslySetInnerHTML={{ __html: event.description }} />
      </Container>
    </>
  );
}
