import { useContext } from "react";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../providers/AppProvider";
import UserMenu from "./UserMenu";
import Link from "@mui/material/Link";

export default function Header() {
  const navigate = useNavigate();
  const { user } = useContext(AppContext);

  const login = () => {
    navigate("/login");
  };

  const signup = () => {
    navigate("/signup");
  };

  const events = () => {
    navigate("/events");
  };

  const organizations = () => {
    navigate("/organizations");
  };

  return (
    <>
      <Toolbar
        sx={{
          borderBottom: 1,
          marginBottom: 2,
          borderColor: "divider",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Link
            color="inherit"
            noWrap
            component="button"
            variant="body2"
            onClick={events}
            sx={{ p: 1, flexShrink: 0 }}
          >
            Events
          </Link>
          <Link
            color="inherit"
            noWrap
            component="button"
            variant="body2"
            onClick={organizations}
            sx={{ p: 1, flexShrink: 0 }}
          >
            Organizations
          </Link>
        </div>
        {user ? (
          <UserMenu />
        ) : (
          <div>
            <Button
              variant="outlined"
              size="small"
              onClick={login}
              sx={{ marginRight: 2 }}
            >
              Login
            </Button>
            <Button variant="outlined" size="small" onClick={signup}>
              Sign up
            </Button>
          </div>
        )}
      </Toolbar>
    </>
  );
}
